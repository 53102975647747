import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import theme from '../../utils/themeconstants';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ContentContainer from '../../components/elements/pageContentContainer';
import BlogImage from './images/tomorrow-blog.svg';

const BLOG_BORDER = 10;

const PageBackground = styled.div`
  width: 100%;
`;

const PageContent = styled.div`
  max-width: 672px;
  text-align: left;
  padding: 100px 1.0875rem 100px;
  margin: 0 auto;
`;

const TopImage = styled.img`
  width: 50%;
  margin: 0 auto 2em;
  @media only screen and (max-width: ${theme.dim.mobilebreakpoint}px){
    width: 50%;
  }
`;

const BlogPostsContainer = styled.div`
  margin-top: ${theme.space.lg * 2}px;
`;

const BlogLink = styled(Link)`
  color: ${theme.colors.primary};
  padding-bottom: 4px;
  transition: color 0.4s;
  &:hover{
    color: ${theme.colors.red};
  }
  &.titleLink{
    padding-bottom: 0;
    text-decoration: none;
  }
`;

const TeaserImageContainer = styled.div`
  margin: 1em 0;
  width: 100%;
  background-color: white;
  overflow: hidden;
`;

const TeaserImage = styled(Img)`
  margin: 0;
`;

const TeaserText = styled.p`
  margin-bottom: ${theme.space.sm}px;
`;


class BlogIndex extends React.PureComponent {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout dark blog>
        <SEO title="Tomorrow - Blog" keywords={['blog', 'tomorrow', 'climate change']} />
        <PageBackground>
          <PageContent>
            <ContentContainer>
              <TopImage src={BlogImage} />
              <h3>Tomorrow Blog</h3>
              {posts.map(({ node }) => {
                const title = node.frontmatter.title || node.fields.slug;
                return (
                  <BlogPostsContainer key={node.fields.slug}>
                    <div key={node.fields.slug}>
                      <h2
                        style={{
                          marginBottom: '16px',
                        }}
                      >
                        <BlogLink className="titleLink" to={`blog${node.fields.slug}`}>
                          {title}
                        </BlogLink>
                      </h2>
                      <small>
                        {node.frontmatter.date}
                        {' · '}
                        {node.fields.readingTime.text}
                      </small>

                      <TeaserImageContainer>
                        <Link to={`blog${node.fields.slug}`}>
                          <TeaserImage fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.title} />
                        </Link>
                      </TeaserImageContainer>
                      <TeaserText
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt,
                        }}
                      />
                      <BlogLink to={`blog${node.fields.slug}`}>
                        Read More
                      </BlogLink>
                    </div>
                  </BlogPostsContainer>
                );
              })}
            </ContentContainer>
          </PageContent>
        </PageBackground>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image{
              childImageSharp {
                fluid(quality: 80, maxWidth: 672) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
